import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerOverlay,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  IconButton,
} from "@chakra-ui/react";
import { useRef } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import ColumnFilters from "./components/ColumnFilters";
function Filter(props) {
  const { columns, filterQuery, setFilterQuery } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  const checkButtonDisabled = Object.keys(filterQuery.filters).length === 0;

  return (
    <>
      <IconButton
        background={"#EDF2F7"}
        aria-label="Search database"
        icon={<SearchIcon />}
        onClick={onOpen}
      />
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="xs"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Filters</DrawerHeader>

          <DrawerBody display={"flex"} flexDir={"column"} gap={4}>
            <ColumnFilters
              columns={columns}
              filterQuery={filterQuery}
              setFilterQuery={setFilterQuery}
            />
          </DrawerBody>

          <DrawerFooter>
            <Button
              variant="outline"
              mr={3}
              onClick={() => {
                const emptyFiltersQuery = {
                  limit: filterQuery.limit || 25,
                  page: filterQuery.page || 1,
                  filters: {},
                  sortAttribute: "",
                  sortDirection: "",
                };
                setFilterQuery(emptyFiltersQuery);
                props.fetchData(emptyFiltersQuery);
                onClose();
              }}
            >
              Clear
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                props.fetchData({ ...filterQuery, page: 1 });
                onClose();
              }}
              isDisabled={checkButtonDisabled}
            >
              Filter
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default Filter;
