import { useState, useEffect } from "react";
import { useToast, Card } from "@chakra-ui/react";
import ListContainer from "components/Wrappers/ListContainer";
import tableActions from "variables/tableActions";
import { columns } from "variables/merchantsData";
import { getMerchants, getMerchantsExport } from "api/merchants";
import { AddMerchant } from "components/actions/merchants/add-merchant";
import { Role } from "../../variables/Roles";
import { ExportAction } from "components/actions/global/export";
import { Loader } from "components/Loader/loader";
function Merchants() {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [filterQuery, setFilterQuery] = useState({
    page: 1,
    limit: 25,
    filters: {},
    sortAttribute: "",
    sortDirection: ""
  });
  const accessLvl = localStorage.getItem("role")
    ? localStorage.getItem("role")
    : "";

  const [merchantsData, setMerchantsData] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);

  const globalActions = [
    {
      name: "add-merchant",
      component: () => <AddMerchant fetchData={fetchData} filterQuery={filterQuery} />,
      validation: () => accessLvl === Role.financeManager,
    },
    {
      name: "export",
      component: () => <ExportAction onclickExport={onExport} />,
      validation: () => true,
    },
  ];

  const onExport = async () => {
    setOpenLoader(true);
    const exportQuery = { ...filterQuery };
    delete exportQuery?.page;
    delete exportQuery?.limit;
    const res = await getMerchantsExport(exportQuery, toast);
    openFileInNewTab(res);
    setOpenLoader(false);
  };

  const openFileInNewTab = (response) => {
    const file = new Blob([response.data], { type: "text/csv" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const fetchData = async (query) => {
    setIsLoading(true);
    const res = await getMerchants(query, toast);
    if (res) {
      setMerchantsData(res.merchants);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(filterQuery);
    columns.find((col) => col.type === "link")["onClickLink"] = (row) => {
      if (accessLvl === Role.financeManager || accessLvl === Role.superFinance)
        window.open("/admin/merchants/" + row._id + "/plan-details", "_blank");
    };
  }, [filterQuery.page]);

  return (
    <>
      <Loader isLoading={openLoader} />

      <Card marginTop={"2rem"} padding={6} borderRadius={40}>
        <ListContainer
          title="Merchants"
          columns={columns}
          data={merchantsData}
          filterQuery={filterQuery}
          setFilterQuery={setFilterQuery}
          isLoading={isLoading}
          tableActions={tableActions["Merchants"]}
          globalActions={globalActions}
          fetchData={fetchData}
          hasPagination={true}
        />
      </Card>
    </>
  );
}

export default Merchants;
